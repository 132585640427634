<template>
  <div class="my-order-container">
    <!-- 菜单 -->
    <div class="my-order-menu-box">
      <ul>
        <li v-for="(item,index) in myOrderMenuData" :key="index" @click="clickMyOrderMenuHandler(index)" >
          <p class="my-order-menu-title" :style="{color:currentMyOrderIndex===index?'#F02233':''}">{{item}}</p>
        </li>
        <div class="slider-bg" :style="{left:myOrderSelectedStyle}"></div>
      </ul>
    </div>
    <!-- 商品信息标题 -->
    <div class="my-order-product-title">
      <ul>
        <li v-for="(item,index) in myOrderProductTitleData" :key="index">
          <p class="my-order-product-title-text">{{item}}</p>
        </li>
      </ul>
    </div>
    <!-- 商品信息 -->
    <div class="my-order-item-info-box" v-if="showOrderList&&showOrderList.length>0">
      <ul>
        <li v-for="(item,index) in showOrderList" :key="index">
          <div class="my-order-item-info-main-pic-box">
            <img class="my-order-item-info-main-pic" :src="item.productCoverUrl" alt="订单商品主图">
          </div>
          <div class="my-order-item-info-title">
            <p class="my-order-item-info-title-text">{{item.productIntroduction}}</p>
          </div>
          <div class="my-order-item-info-order-number">
            <p class="my-order-item-info-order-number-text">{{item.orderNo.slice(6)}}</p>
          </div>
          <div class="my-order-item-info-price-box">
            <p class="my-order-item-info-price-number">￥{{item.productPrice.toFixed(2)}}</p>
            <!-- <p class="my-order-item-info-price-freight">含运费：10.00元</p> -->
            <p class="my-order-item-info-price-pay-mode">在线支付</p>
          </div>
          <div class="my-order-item-info-status-box">
            <p class="my-order-item-info-status-text" :style="{color:statusStyle(item.orderStatus)}">{{showOrderStatus(item.orderStatus)}}</p>
          </div>
          <div class="my-order-item-info-handle-box">
            <div class="my-order-item-info-order-payment-btn" v-if="item.orderStatus==1">
              <p class="my-order-item-info-order-payment-btn-text">立即付款</p>
            </div>
            <div class="my-order-item-info-order-detail-btn">
              <p class="my-order-item-info-order-detail-btn-text">订单详情</p>
            </div>
            <div class="my-order-item-info-order-cancel-btn">
              <p class="my-order-item-info-order-cancel-btn-text" v-if="item.orderStatus===1">取消订单</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <no-product-page v-else :boxWidth="1200" :boxHeight="468"></no-product-page>
  </div>
</template>

<script>
import {getWebOrderInfoList} from '@/services/myCenter/index.js';
import NoProductPage from '../../components/no-product-page.vue';
export default {
  name:"MyOrder",
  components:{
    NoProductPage
  },
  data(){
    return {
      myOrderMenuData:["全部","待付款","进行中","已完成","退款/售后"],
      myOrderProductTitleData:["商品信息","订单号","金额","状态","操作"],
      currentMyOrderIndex:0,
      myOrderList:[],
      requestOrderInfoParam:{
        pageNumber:1,
        pageSize:20,
        orderStatus:0
      }
    }
  },
  methods:{
    clickMyOrderMenuHandler(index){
      this.currentMyOrderIndex = index;
      this.requestOrderInfoParam.orderStatus = index;
      this.loadWebOrderInfoList();
    },
    showOrderStatus(status){
      if(status===1){
        return '待付款'
      }else if(status===2){
        return '进行中'
      }else if(status===3){
        return '已完成'
      }else if(status===4){
        return '已退款'
      }else if(status===5){
        return '已取消'
      }
    },
    statusStyle(status){
      if(status===1){
        return '#F02233'
      }else if(status===2){
        return '#1ECB6A'
      }else if(status===3){
        return '#666666'
      }
    },
    //加载订单列表
    async loadWebOrderInfoList(){
      try{
        const {data} = await getWebOrderInfoList(this.requestOrderInfoParam);
        //console.log(data)
        if(data.code===200){
          this.myOrderList = data.data.list;
        }
      }catch(err){}
    }
  },
  computed:{
    myOrderSelectedStyle(){
      return this.currentMyOrderIndex*100+4+'px';
    },
    showOrderList(){
      const tempArr =  this.myOrderList.filter(item=>{
        return item.orderStatus===this.currentMyOrderIndex;
      });
      //console.log('&&'+tempArr)
      return this.currentMyOrderIndex===0?this.myOrderList:tempArr;
    },
  },
  created(){
    this.loadWebOrderInfoList();
  }
}
</script>

<style lang="less" scoped>
.my-order-container{
    width: 1200px;
    background: #FFFFFF;
    margin-top: 1px;
    padding-top: 20px;
    padding-bottom: 30px;
    border-radius: 0px 0px 8px 8px;
    .my-order-menu-box{
      width: 1200px;
      height: 36px;
      ul{
        margin-left: 20px;
        width: 508px;
        height: 36px;
        background: #F5F5F5;
        border-radius: 6px;
        list-style-type: none;
        display: flex;
        padding: 4px;
        cursor: default;
        position: relative;
        li{
          width: 100px;
          height: 28px;
          list-style: none;
          display: flex;
          justify-content: center;
          align-items: center;
          .my-order-menu-title{
            z-index: 99;
            height: 19px;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #808080;
            line-height: 19px;
            text-align: right;
            font-style: normal;
          }
          &:hover{
            border-radius: 4px;
          }
          &:hover .my-order-menu-title{
            color: #F02233;
          }
        }
        .slider-bg{
          z-index: 0;
          position: absolute;
          left: 4px;
          top: 4px;
          width: 100px;
          height: 28px;
          background: #FFFFFF;
          border-radius: 4px;
          transition: all 0.3s ease-in-out;
        }
      }
    }
    .my-order-product-title{
      margin-top: 20px;
      width: 1200px;
      height: 38px;
      display: flex;
      align-items: center;
      ul{
        margin-left: 20px;
        width: 1160px;
        height: 38px;
        list-style-type: none;
        padding-left: 20px;
        padding-top: 10px;
        background: #F5F5F5;
        border-radius: 4px;
        display: flex;
        cursor: default;
        li{
          list-style: none;
          .my-order-product-title-text{
            height: 17px;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: bold;
            font-size: 13px;
            color: #222222;
            line-height: 17px;
            text-align: left;
            font-style: normal;
          }
          &:nth-child(2){
            margin-left: 370px;
          }
          &:nth-child(3){
            margin-left: 182px;
          }
          &:nth-child(n+4){
            margin-left: 173px;
          }
        }
        
      }
    }

    .my-order-item-info-box{
      width: 1200px;
      ul{
        width: 1200px;
        list-style-type: none;
        padding: 0;
        cursor: default;
        display: flex;
        flex-wrap: wrap;
        li{
          margin-top: 20px;
          margin-left: 20px;
          list-style: none;
          width: 1160px;
          height: 116px;
          padding: 20px;
          background: #FFFFFF;
          border-radius: 4px;
          border: 1px solid #EEEEEE;
          display: flex;
          align-items: center;
          .my-order-item-info-main-pic-box{
            width: 136px;
            height: 76px;
            .my-order-item-info-main-pic{
              width: 136px;
              height: 76px;
            }
          }
          .my-order-item-info-title{
            margin-left: 20px;
            width: 197px;
            height: 63px;
            .my-order-item-info-title-text{
              width: 197px;
              height: 63px;
              font-family: MicrosoftYaHei;
              font-size: 14px;
              color: #131415;
              line-height: 21px;
              text-align: left;
              font-style: normal;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              line-clamp: 3;
              -webkit-line-clamp: 3;
              word-break: break-all;
            }
          }
          .my-order-item-info-order-number{
            margin-left: 66px;
            width: 144px;
            .my-order-item-info-order-number-text{
              width: 144px;
              height: 19px;
              font-family: MicrosoftYaHei;
              font-size: 14px;
              color: #131415;
              line-height: 19px;
              text-align: left;
              font-style: normal;
            }
          }
          .my-order-item-info-price-box{
            width: 90px;
            margin-left: 77px;
            height: 116px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .my-order-item-info-price-number{
              height: 16px;
              font-family: MicrosoftYaHei, MicrosoftYaHei;
              font-weight: bold;
              font-size: 16px;
              color: #F02233;
              line-height: 16px;
              text-align: left;
              font-style: normal;
            }
            .my-order-item-info-price-freight{
              margin-top: 10px;
              height: 12px;
              font-family: MicrosoftYaHei;
              font-size: 12px;
              color: #666666;
              line-height: 12px;
              text-align: left;
              font-style: normal;
            }
            .my-order-item-info-price-pay-mode{
              margin-top: 10px;
              height: 12px;
              font-family: MicrosoftYaHei;
              font-size: 12px;
              color: #666666;
              line-height: 12px;
              text-align: left;
              font-style: normal;
            }
          }
          .my-order-item-info-status-box{
            width: 48px;
            margin-left: 116px;
            height: 116px;
            display: flex;
            align-items: center;
            .my-order-item-info-status-text{
              height: 16px;
              font-family: MicrosoftYaHei, MicrosoftYaHei;
              font-weight: bold;
              font-size: 16px;
              color: #1ECB6A;
              line-height: 16px;
              text-align: left;
              font-style: normal;
            }
          }
          .my-order-item-info-handle-box{
            margin-left: 112px;
            width: 100px;
            height: 116px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .my-order-item-info-order-payment-btn{
              width: 98px;
              height: 30px;
              background: #F02233;
              border-radius: 18px;
              display: flex;
              justify-content: center;
              align-items: center;
              .my-order-item-info-order-payment-btn-text{
                height: 16px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                font-size: 12px;
                color: #FFFFFF;
                line-height: 16px;
                text-align: right;
                font-style: normal;
              }
            }
            .my-order-item-info-order-detail-btn{
              margin-top: 6px;
              .my-order-item-info-order-detail-btn-text{
                height: 19px;
                font-family: MicrosoftYaHei;
                font-size: 14px;
                color: #3C8DFC;
                line-height: 19px;
                text-align: left;
                font-style: normal;
              }
            }
            .my-order-item-info-order-cancel-btn{
              margin-top: 6px;
              .my-order-item-info-order-cancel-btn-text{
                height: 19px;
                font-family: MicrosoftYaHei;
                font-size: 14px;
                color: #F02233;
                line-height: 19px;
                text-align: left;
                font-style: normal;
              }
            }
          }
        }
      }
    }


}
</style>